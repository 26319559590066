




























































































































































































































































































































































































































































































































































































































































































































import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import SessionsModule from '@/store/modules/Sessions/module';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import SelectedDays from '@/commoncomponents/SelectedDays.vue';
import CancelConfirmation from '@/popupcomponents/cancelConfirmation/CancelConfirmation.vue';
import SessionPaymentChangeConfirmation from '@/popupcomponents/SessionPaymentChangeConfirmation/SessionPaymentChangeConfirmation.vue';
import APP_CONST from '@/constants/AppConst';
import { AddSessionPayload, EditSessionPayload, SessionWithExistingSiteLocation } from '@/Model/sessions/types';
import ExpandableSelectList from '@/commoncomponents/ExpandableSelectList/ExpandableSelectList.vue';
import BouncingPreloader from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ProgramsModule from '@/store/modules/Programs/module';
import SiteLocationsModule from '@/store/modules/SiteLocations/module';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { GetAllSiteLocationsRequest, SiteLocationResponse, VerifiedSiteLocationResponse, VerifiedSiteLocationResponseLocation } from '@/Model/siteLocations/types';
import TimeComboBox from '@/commoncomponents/TimeComboBox.vue';
import { ProgramData } from '@/Model/programs/types';
import RemoveSessionAttendanceWarning from '@/popupcomponents/removeSessionAttendanceWarning/removeSessionAttendanceWarning.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { FormValidationError, VeeValidateProviderMode } from '@/Model/forms/types';
import { CurrencyInput, CurrencyInputOptions } from 'vue-currency-input';
import { ValidationProvider } from 'vee-validate';
import { ProviderInstance } from 'vee-validate/dist/types/types';
import PaymentsModule from '@/store/modules/Payments/module';
import { OnboardingStatus, PriceResponse, PriceType, RecurringPaymentIntervalDropdownOptions, RecurringPaymentBillingCycleDropdownOptions, SessionBillingCycle, PriceRequest } from '@/Model/payments/types';
import { ApiErrorType } from '@/Model/errors/api/types';
import { PaymentFrequencyRadioInputOption } from '@/Model/sessions/types';
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types';
import { registerPriceGreaterThanZeroWithTaxRateValidator, registerPriceMinValueValidator, registerPriceRequiredValidator, requiredField } from '@/validators/sessions/validators';
import { findSessionPrice } from '@/services/sessions/helpers';
import { formatCurrency } from '@/services/common/formatters';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

const timeFormatType = 'en-US';
const MIN_BILLING_CYCLES = 1, MIN_RECURRING_BILLING_CYCLES = 2;
const MAX_BILLING_CYCLES_WEEKLY = 16, MAX_BILLING_CYCLES_MONTHLY = 12;

@Component({
  components: {
    OffCanvas,
    DatepickerComponent,
    ProgressButton,
    SelectedDays,
    CancelConfirmation,
    ExpandableSelectList,
    BouncingPreloader,
    TimeComboBox,
    RemoveSessionAttendanceWarning,
    CurrencyInput,
    ValidationProvider,
    SessionPaymentChangeConfirmation,
    DropdownList
  }
})

export default class AddEditSession extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  readonly screenText = new ScreenText();
  readonly siteLocationsModule = SiteLocationsModule;
  readonly paymentsModule = PaymentsModule;
  readonly programsModule = ProgramsModule;
  readonly sessionNameMaxLength = 100;
  readonly scholarCapacityMaxLength = 100;
  readonly defaultOpenTime = '8:00 AM';
  readonly currencyOptions: CurrencyInputOptions = APP_CONST.CURRENCY_OPTIONS;
  private readonly accountId = APP_UTILITIES.getAccountId();
  private currentUserRole = +(APP_UTILITIES.getCookie('highest_role') || APP_CONST.ZERO);

  private allowedRoles: Array<number> = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.BX_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN,
    APP_CONST.ROLES.PROGRAM_ADMIN,
    APP_CONST.ROLES.SESSION_ADMIN,
  ];
  private allowedRolesForAutoEnrollEdit: Array<number> = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.BX_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN,
    APP_CONST.ROLES.PROGRAM_ADMIN,
    APP_CONST.ROLES.SESSION_ADMIN,
  ];
  private allowedRolesNavToPayments: Array<number> = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN
  ];
  private userCanEdit = this.allowedRoles.includes(this.currentUserRole);
  private userCanEditAutoEnrollAfterStart = this.allowedRolesForAutoEnrollEdit.includes(this.currentUserRole);
  private userCanNavigateToPayments = this.allowedRolesNavToPayments.includes(this.currentUserRole);
  private persistedWaitListIsUnlimited: boolean | null | undefined = null;

  paymentFrequencyInputOptions: Array<PaymentFrequencyRadioInputOption> = [
    { id: 'payment-frequency-one-time', option: PriceType.OneTime, displayText: this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_ONE_TIME_TEXT') },
    { id: 'payment-frequency-recurring', option: PriceType.Recurring, displayText: this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_RECURRING_TEXT') },
  ];
  selectedPaymentFrequencyInput: PriceType = this.paymentFrequencyInputOptions[0].option;

  recurringPaymentIntervalDropdownOptions: Array<RecurringPaymentIntervalDropdownOptions> = [
    { interval: SessionBillingCycle.OneTime, displayText: this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_DEFAULT') },
    { interval: SessionBillingCycle.Weekly, displayText: this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_WEEKLY') },
    { interval: SessionBillingCycle.Monthly, displayText: this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_MONTHLY') },
  ];
  recurringPaymentBillingCycleDropdownOptions: Array<RecurringPaymentBillingCycleDropdownOptions> = [{ billingCycle: MIN_RECURRING_BILLING_CYCLES, displayText: MIN_RECURRING_BILLING_CYCLES.toString() }];
  isPaymentPlanSessionEnabled: boolean = false;
  sessionData: AddSessionPayload = {
    programId: undefined,
    sessionName: '',
    note1: undefined,
    note2: undefined,
    scholarCapacity: undefined,
    waitListCapacity: undefined,
    autoEnrollCapacity: undefined,
    autoEnroll: false,
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    ageAsOf: null,
    ageTo: undefined,
    ageFrom: undefined,
    selectedDays: [],
    isTimeAdded: false,
    siteLocationId: undefined,
    priceRequest: {
      price: 0,
      billingCycleCount: MIN_BILLING_CYCLES,
    },
    taxRate: null,
    waitListIsUnlimited: null,
  };

  @PropSync('isAddEditSessionVisible', { type: Boolean, required: true }) isAddEditSessionVisibleSync!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isEditMode!: boolean;
  @Prop({ type: Object, default: null }) readonly selectedSessionForEdit!: SessionWithExistingSiteLocation | null;

  @Prop({ type: Boolean, default: false }) readonly paymentPlanFlagEnabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly accountPaymentPlanEnabled!: boolean;

  @Ref('autoEnrollmentDropdown') readonly autoEnrollmentDropdown!: HTMLElement;
  @Ref('priceProviderRef') priceProviderRef?: ProviderInstance;
  @Ref('sessionCapacityProviderRef') sessionCapacityProviderRef?: ProviderInstance;
  @Ref('waitListCapacityProviderRef') waitListCapacityProviderRef?: ProviderInstance;

  initialPaymentPlanCheckboxValue = this.accountPaymentPlanEnabled;

  async beforeMount() {
    if (this.paymentPlanFlagEnabled) {
      Vue.set(this.sessionData, 'isPaymentPlanEnabled', this.paymentPlanFlagEnabled);
    } else {
      Vue.set(this.sessionData.priceRequest, 'billingCycle', SessionBillingCycle.OneTime);
    }
  }

  @Watch('selectedPaymentFrequencyInput')
  onPaymentFrequencyInputChange() {
    const selectedSession = this.selectedSessionForEdit;
    const paymentFrequencyIsOneTime = this.selectedPaymentFrequencyInput === PriceType.OneTime;
    if (this.isEditMode && selectedSession && selectedSession.prices && this.priceProviderRef) {
      let priceBeingEdited = null;
      if (paymentFrequencyIsOneTime) {
        priceBeingEdited = selectedSession.prices.find(p => p.billingCycleCount === undefined);
      }
      else {
        priceBeingEdited = selectedSession.prices.find(p => p.billingCycleCount);
      }
      this.sessionData.priceRequest.price = priceBeingEdited
        ? priceBeingEdited.price
        : 0;
      this.priceProviderRef.syncValue(this.sessionData.priceRequest.price);
      this.priceProviderRef.reset();
    }
    if (!this.isEditMode && paymentFrequencyIsOneTime) {
      this.hasRecurringWithNoPriceError = false;
      this.hasRecurringWithNoIntervalError = false;
      this.sessionData.priceRequest.billingCycle = this.recurringPaymentIntervalDropdownOptions[0].interval;
      this.sessionData.priceRequest.billingCycleCount = this.recurringPaymentBillingCycleDropdownOptions[0].billingCycle;
    }
  }

  @Watch('sessionData.priceRequest.billingCycle')
  onRecurringPaymentIntervalChange() {
    if (this.sessionData.priceRequest.billingCycle !== SessionBillingCycle.OneTime) {
      this.createBillingCycleDropdownOptionsArray();
    }
  }

  @Watch('isAddEditSessionVisible')
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.getPaymentData();
    }

    const shouldSetInitialSessionDataForEdit =
      visible &&
      this.isEditMode &&
      this.hasEditData &&
      this.isNewSessionToAddOrEdit;

    if (shouldSetInitialSessionDataForEdit) {
      this.setSessionDataToSelectedSessionForEdit();
      this.isNewSessionToAddOrEdit = false;
    }
  }

  private async getPaymentData() {
    await this.paymentsModule.getOnboardingStatus(this.accountId);
    if (this.merchantOnboardingCompleted) {
      this.fetchAllTaxRates();
    }
  }

  hasUserSubmittedForm = false;
  isValidForProgressButton = true;
  isFormDirty = false;
  isCancelConfirmationVisible = false;
  userConfirmedCancel = false;
  selectedSite: SiteLocationResponse | VerifiedSiteLocationResponse | null = null;
  isSelectSitePanelOpen: boolean = false;
  sitesExcludedSearchFields:
    Array<keyof SiteLocationResponse | keyof VerifiedSiteLocationResponse | keyof VerifiedSiteLocationResponseLocation> =
    ['accountId', 'addressHash', 'createdAt', 'id', 'locationId', 'locationVerificationId', 'status'];
  duplicateErrorStatus = false;
  sessionsModule = SessionsModule;

  programStartDate: Date = new Date(0);
  programEndDate: Date = new Date(0);

  startTimeError: FormValidationError | null = null;
  startTimeErrorMessage = '';

  endTimeError: FormValidationError | null = null;
  endTimeErrorMessage = '';

  hasStartDateError = false;
  hasEndDateError = false;

  hasSessionDatesExceedProgramError = false;

  hasRecurringWithNoPriceError = false;
  hasRecurringWithNoIntervalError = false;

  isRemoveAttendancePopupVisible = false;
  isPaymentSettingsChangeConfirmationVisible = false;

  skipCancelConfirmation = false;

  isNewSessionToAddOrEdit = true;

  private selectATaxRateDropdownItem: DropdownListItem = {
    id: 0,
    value: this.screenText.getScreenText('SESSION_ADD_EDIT_TAX_DEFAULT')
  };

  get isPaymentPlanCheckboxDirty() {
    return this.sessionData.isPaymentPlanEnabled !== this.initialPaymentPlanCheckboxValue;
  }

  get waitListEnabled() {
    return this.sessionData.waitListIsUnlimited !== null && this.sessionData.waitListIsUnlimited !== undefined;
  }

  set waitListEnabled(newValue: boolean) {
    if (!newValue) {
      this.persistedWaitListIsUnlimited = this.sessionData.waitListIsUnlimited;
      this.sessionData.waitListIsUnlimited = null;
      return;
    }
    this.sessionData.waitListIsUnlimited = this.persistedWaitListIsUnlimited !== null && this.persistedWaitListIsUnlimited !== undefined
      ? this.persistedWaitListIsUnlimited
      : newValue;
    this.persistedWaitListIsUnlimited = null;
  }

  get titleText(): string {
    const screenTextKey = this.isEditMode
      ? 'SESSIONS_EDIT_SESSION_BUTTON_TEXT'
      : 'SESSIONS_ADD_SESSION_BUTTON_TEXT';
    return this.screenText.getScreenText(screenTextKey);
  }

  get addEditSessionSiteSelectTitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_SITE_SELECT_TITLE');
  }

  get addEditSessionSiteSelectSubtitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_SITE_SELECT_SUBTITLE');
  }

  get addEditSessionNoSitesText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_NO_SITES_TEXT');
  }

  get addEditSessionName(): string {
    return this.screenText.getScreenText('SESSION_ADD_SESSION_NAME');
  }

  get addSessionGeneralInfoHeader(): string {
    return this.screenText.getScreenText('SESSION_ADD_GENERAL_INFO');
  }

  get scholarCapacityTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_SCHOLAR_CAPACITY_TITLE');
  }

  get enrollmentCriteriaTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_ENROLLMENT_CRITERIA_TITLE');
  }

  get sessionStartDateTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_START_DATE_TITLE');
  }

  get sessionEndDateTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_END_DATE_TITLE');
  }

  get sessionNameUniqueHint(): string {
    return this.screenText.getScreenText('SESSION_ADD_NAME_UNIQUE_HINT');
  }

  get cancelButtonText(): string {
    return this.screenText.getScreenText('BTN_CANCEL');
  }

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE');
  }

  get sessionAgeAsOfTitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_AGE_AS_OF_TITLE');
  }

  get sessionAgeRangeTitle(): string {
    return this.screenText.getScreenText('SESSION_ADD_AGE_RANGE_TITLE');
  }

  get sessionStartTimeTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_START_TIME_TITLE');
  }

  get sessionEndTimeTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_END_TIME_TITLE');
  }

  get autoEnrollmentHelperText(): string {
    return this.screenText.getScreenText('SESSION_ADD_AUTO_ENROLLMENT_TEXT');
  }

  get addSessionScheduleHeaderText(): string {
    return this.screenText.getScreenText('SESSION_ADD_SCHEDULE_HEADER_TEXT');
  }

  get enrollmentCriteriaHeaderText(): string {
    return this.screenText.getScreenText('SESSION_ADD_ENROLLMENT_CRITERIA_HEADER');
  }

  get addSessionScheduleSubheaderText(): string {
    return this.screenText.getScreenText('SESSION_ADD_SCHEDULE_SUBHEADER_TEXT');
  }

  get paymentSettingsHeaderText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_HEADER_TEXT');
  }

  get attendanceAccuracyHint(): string {
    return this.screenText.getScreenText('SESSION_ADD_ATTENDANCE_ACCURACY_HINT');
  }

  get autoEnrollmentIconHelperText(): string {
    return this.screenText.getScreenText('SESSION_ADD_AUTO_ENROLLMENT_ICON_TEXT');
  }

  get ageRangeHelperText(): string {
    return this.screenText.getScreenText('SESSION_ADD_AGE_RANGE_HELPER_TEXT');
  }

  get ageAsOfHelperText(): string {
    return this.screenText.getScreenText('SESSION_ADD_AGE_AS_OF_HELPER_TEXT');
  }

  get startDateErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_START_DATE_REQUIRED');
  }

  get endDateErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_END_DATE_REQUIRED');
  }

  get sessionDatesExceedProgramError(): string {
    return this.screenText.getScreenText('SESSION_ADD_DATES_EXCEED_PROGRAM_ERROR');
  }

  get shouldApplyErrorClassForSessionName(): boolean {
    return !!this.sessionsModule.sessionSaveFailedError && this.sessionsModule.sessionSaveFailedError.value === this.screenText.getScreenText('SESSION_NAME_CONFLICT');
  }

  get enablePaymentPlansMessage(): string {
    return this.screenText.getScreenText('LABEL_ENABLE_PAYMENT_PLANS');
  }

  get sessionStartDateMax(): Date | null {
    const sessionEndDate = this.sessionData.endDate;
    if (sessionEndDate && sessionEndDate instanceof Date) {
      return sessionEndDate;
    }
    return this.programEndDate;
  }

  get sessionEndDateMin(): Date | null {
    const sessionStartDate = this.sessionData.startDate;
    if (sessionStartDate && sessionStartDate instanceof Date) {
      return sessionStartDate;
    }
    return null;
  }

  get startDateOpenDate(): Date {
    if (this.sessionData.startDate != null) {
      return this.sessionData.startDate;
    }
    else if (this.sessionData.endDate != null) {
      return this.sessionData.endDate;
    }
    else {
      return new Date();
    }
  }

  get endDateOpenDate(): Date {
    if (this.sessionData.endDate != null) {
      return this.sessionData.endDate;
    }
    else if (this.sessionData.startDate != null) {
      return this.sessionData.startDate;
    }
    else {
      return new Date();
    }
  }

  get startTimeOpenTime(): string {
    if (this.sessionData.startTime) {
      return this.sessionData.startTime;
    }
    else if (this.sessionData.endTime) {
      const endTimeAsDate = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.sessionData.endTime);
      const minusOneHour = endTimeAsDate.getHours() - 1;
      endTimeAsDate.setHours(minusOneHour >= 0
        ? minusOneHour
        : 0);
      const endTimeAsDateString = endTimeAsDate.toLocaleTimeString(timeFormatType, { hour: 'numeric', hour12: true, minute: 'numeric' });
      return endTimeAsDateString === 'Invalid Date'
        ? this.defaultOpenTime
        : endTimeAsDateString;
    }
    else {
      return this.defaultOpenTime;
    }
  }

  get endTimeOpenTime(): string {
    if (this.sessionData.endTime) {
      return this.sessionData.endTime;
    }
    else if (this.sessionData.startTime) {
      const startTimeAsDate = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.sessionData.startTime);
      const plusOneHour = startTimeAsDate.getHours() + 1;
      startTimeAsDate.setHours(plusOneHour < 24
        ? plusOneHour
        : 23);
      const startTimeAsDateString = startTimeAsDate.toLocaleTimeString(timeFormatType, { hour: 'numeric', hour12: true, minute: 'numeric' });
      return startTimeAsDateString === 'Invalid Date'
        ? this.defaultOpenTime
        : startTimeAsDateString;
    }
    else {
      return this.defaultOpenTime;
    }
  }

  get enableWaitListLabelText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_ENABLE_WAIT_LIST_LABEL_TEXT');
  }

  get waitListCapacityLabelText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_WAIT_LIST_CAPACITY_LABEL_TEXT');
  }

  get waitListCapacityFieldText(): string {
    return this.waitListCapacityLabelText.toLowerCase();
  }

  get sessionCapacityFieldText(): string {
    return this.scholarCapacityTitleText.toLowerCase();
  }

  get waitListUnlimitedLabelText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_WAIT_LIST_UNLIMITED_LABEL_TEXT');
  }

  get waitListLimitedLabelText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_WAIT_LIST_LIMITED_LABEL_TEXT');
  }

  get shouldDisplayClosingSessionHint(): boolean {
    return !this.waitListEnabled
      || this.waitListEnabled && !this.sessionData.waitListIsUnlimited;
  }

  get closingSessionHint(): string {
    return this.screenText.getScreenText(this.waitListEnabled && !this.sessionData.waitListIsUnlimited
      ? 'SESSION_ADD_EDIT_SESSION_CLOSE_LIMITED_WAIT_LIST_HINT_TEXT'
      : 'SESSION_ADD_EDIT_SESSION_CLOSE_HINT_TEXT');
  }

  dateChange(date: Date) {
    this.sessionData.ageAsOf = this.stripTime(date);
  }

  get hasAnyDateErrors(): boolean {
    this.hasStartDateError = false;
    this.hasEndDateError = false;
    this.hasSessionDatesExceedProgramError = false;

    const hasSessionStartDate = !!this.sessionData.startDate;
    const hasSessionEndDate = !!this.sessionData.endDate;

    if (hasSessionStartDate === false) {
      this.hasStartDateError = true;
    }

    if (hasSessionEndDate === false) {
      this.hasEndDateError = true;
    }

    if (hasSessionStartDate) {
      const currentStartDateTime = new Date(this.sessionData.startDate as Date).getTime();
      const programStartDateTime = this.programStartDate.getTime();
      const isSessionEarlierThanProgramStartDate = currentStartDateTime < programStartDateTime;
      if (isSessionEarlierThanProgramStartDate) {
        this.hasSessionDatesExceedProgramError = true;
      }
    }

    if (hasSessionEndDate) {
      const currentEndDateTime = new Date(this.sessionData.endDate as Date).getTime();
      const programEndDateTime = this.programEndDate.getTime();
      const isSessionLaterThanProgramEndDate = currentEndDateTime > programEndDateTime;
      if (isSessionLaterThanProgramEndDate) {
        this.hasSessionDatesExceedProgramError = true;
      }
    }

    return this.hasStartDateError || this.hasEndDateError || this.hasSessionDatesExceedProgramError;
  }

  get hasTimeError(): boolean {
    return this.startTimeError !== null || this.endTimeError !== null;
  }

  get isInitialStartDatePast(): boolean {
    if (this.selectedSessionForEdit) {
      const now = (new Date()).setHours(0, 0, 0, 0);
      const initialStartDate = (new Date(this.selectedSessionForEdit.startDateTime)).setHours(0, 0, 0, 0);
      return initialStartDate <= now;
    }
    return false;
  }

  get disableInputForSessionAdminIfStartDatePast(): boolean {
    const roleIsSessionAdmin = this.currentUserRole === APP_CONST.ROLES.SESSION_ADMIN;
    return this.isInitialStartDatePast && roleIsSessionAdmin;
  }

  get disableCommonBase(): boolean {
    return this.isEditMode && !!this.selectedSessionForEdit;
  }

  get disableBasedOnUserRole(): boolean {
    return this.disableCommonBase && !this.userCanEdit;
  }

  get disableAutoEnrollmentEdit(): boolean {
    return this.isInitialStartDatePast && !this.userCanEditAutoEnrollAfterStart;
  }

  private get hasEditData(): boolean {
    return !!this.selectedSessionForEdit;
  }

  private get isTimeSet(): boolean {
    return (!!this.sessionData.startTime && this.sessionData.startTime !== '') || (!!this.sessionData.endTime && this.sessionData.endTime !== '');
  }

  get translatedLoadingToProgressButtonState(): number {
    let buttonState = 0;

    if (this.sessionsModule.isSavingSessions === false && this.sessionsModule.didSessionsSaveFail === false) {
      buttonState = 200;
    }
    else if (this.sessionsModule.isSavingSessions === false && this.sessionsModule.didSessionsSaveFail) {
      buttonState = 400;
    }

    return buttonState;
  }

  get sessionPriceTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PRICE_TITLE');
  }

  get sessionPriceRequiredErrorText(): string {
    return `${this.screenText.getScreenText('SESSION_ADD_EDIT_PRICE_TITLE')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
  }

  get sessionPriceInvalidErrorText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PRICE_INVALID_ERROR');
  }

  get sessionTaxTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_TAX_TITLE');
  }

  get sessionTaxPriceGreaterThanZeroErrorText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_TAX_PRICE_GREATER_THAN_ZERO_ERROR');
  }

  get sessionPriceValidationProviderRules(): Record<string, any> {
    return {
      price_required: true,
      price_min_value: 0,
      price_greater_than_zero_with_tax_rate: {
        taxRate: this.sessionData.taxRate
      }
    };
  }

  get sessionPaymentFrequencyTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_TITLE');
  }

  get sessionPaymentFrequencyIntervalTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_INTERVAL_TITLE');
  }

  get sessionPaymentFrequencyBillingCyclesTitleText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_FREQUENCY_BILLING_CYCLES_TITLE');
  }

  get shouldShowPaymentIntervalDropdown(): boolean {
    return this.selectedPaymentFrequencyInput === PriceType.Recurring;
  }

  get recurringPaymentIntervalDropdownListOptions(): DropdownListOptions {
    let selectedRecurringPaymentInterval = this.recurringPaymentIntervalDropdownOptions[0];
    const dropdownList: Array<DropdownListItem> = this.recurringPaymentIntervalDropdownOptions.map((recurringPaymentOption) => {
      if (this.sessionData.priceRequest.billingCycle === recurringPaymentOption.interval) {
        selectedRecurringPaymentInterval = recurringPaymentOption;
      }
      return {
        id: recurringPaymentOption.interval,
        value: recurringPaymentOption.displayText
      };
    });
    return {
      id: selectedRecurringPaymentInterval.interval,
      singleSelect: true,
      showSelectLabel: false,
      value: selectedRecurringPaymentInterval.displayText,
      dropdownList,
      error: this.hasRecurringWithNoIntervalError,
      disable: !this.userCanEdit
    };
  }

  get recurringPaymentBillingCycleDropdownListOptions(): DropdownListOptions {
    let selectedRecurringPaymentBillingCycle = this.recurringPaymentBillingCycleDropdownOptions[0];
    const dropdownList: Array<DropdownListItem> = this.recurringPaymentBillingCycleDropdownOptions.map((recurringPaymentOption) => {
      if (this.sessionData.priceRequest.billingCycleCount === recurringPaymentOption.billingCycle) {
        selectedRecurringPaymentBillingCycle = recurringPaymentOption;
      }
      return {
        id: recurringPaymentOption.billingCycle,
        value: recurringPaymentOption.displayText
      };
    });
    return {
      id: selectedRecurringPaymentBillingCycle.billingCycle,
      singleSelect: true,
      showSelectLabel: false,
      value: selectedRecurringPaymentBillingCycle.displayText,
      dropdownList,
      error: this.hasRecurringWithNoIntervalError,
      disable: this.disableBasedOnIntervalSelected || !this.userCanEdit
    };
  }

  get hasAnyPaymentFrequencyErrors(): boolean {
    this.hasRecurringWithNoPriceError = false;
    this.hasRecurringWithNoIntervalError = false;

    if (this.isRecurringSelectedWithNoPrice()) {
      this.hasRecurringWithNoPriceError = true;
    }

    if (this.isRecurringSelectedWithNoInterval()) {
      this.hasRecurringWithNoIntervalError = true;
    }

    return this.isRecurringSelectedWithNoPrice() || this.isRecurringSelectedWithNoInterval();
  }

  get recurringWithNoPriceErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_ERROR_RECURRING_NO_PRICE_TEXT');
  }
  get sessionCapacityErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_SCHOLAR_CAPACITY_TITLE_ERROR');
  }

  get recurringWithNoIntervalErrorMessage(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_ERROR_RECURRING_NO_INTERVAL_TEXT');
  }

  get disableBasedOnIntervalSelected(): boolean {
    return this.isRecurringSelectedWithNoInterval();
  }

  get validationMode(): VeeValidateProviderMode {
    return this.hasUserSubmittedForm
      ? VeeValidateProviderMode.Lazy
      : VeeValidateProviderMode.Passive;
  }

  get sessionSaveError(): string {
    const genericApiErrors = [ApiErrorType.Error_SessionWasNotCreated, ApiErrorType.Error_SessionWasNotUpdated];
    const timeErrorMessage = this.startTimeErrorMessage || this.endTimeErrorMessage;
    let apiErrorMessage = '';
    if (this.sessionsModule.didSessionsSaveFail && this.sessionsModule.sessionSaveFailedError) {
      if (genericApiErrors.includes(this.sessionsModule.sessionSaveFailedError.name)) {
        apiErrorMessage = this.screenText.getScreenText('SESSION_ADD_EDIT_UNABLE_TO_SAVE_ERROR_TEXT');
      }
      else {
        apiErrorMessage = this.sessionsModule.sessionSaveFailedError.value;
      }
    }
    return timeErrorMessage || apiErrorMessage;
  }

  get sessionPaymentTitleText(): string {
    return this.screenText.getScreenText('SESSION_PRICE_TITLE');
  }

  get isFetchingOnboardingStatus(): boolean {
    return this.paymentsModule.isFetchingOnboardingStatus;
  }

  get merchantOnboardingCompleted(): boolean {
    if (this.paymentsModule.stripeAccountResponse) {
      return this.paymentsModule.stripeAccountResponse.onboardingStatus === OnboardingStatus.Completed;
    }
    return false;
  }

  get userAuthorizedToViewPayments(): boolean {
    return this.userCanNavigateToPayments;
  }

  get routeToPayments(): string {
    return APP_CONST.APP_ROUTES.ACCOUNT_PAYMENTS.path;
  }

  get errorOutstandingRequirementsText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_ERROR_OUTSTANDING_TEXT');
  }

  get errorAuthorizedText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_ERROR_AUTH_TEXT');
  }

  get errorUnauthorizedText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_ERROR_UNAUTH_TEXT');
  }

  get taxDropdownOptions(): DropdownListOptions {
    let dropdownList: Array<DropdownListItem> = [this.selectATaxRateDropdownItem];
    let selectedTaxRate = this.selectATaxRateDropdownItem;

    dropdownList = dropdownList.concat(this.paymentsModule.taxRates.map((taxRate, index) => {
      const zeroBasedIndex = index;
      const convertedOneBasedIndexForDropdown = zeroBasedIndex + 1; // Need to have an Number id for the bx dropdown
      const taxRateDropdownItemForIndex: DropdownListItem = {
        id: convertedOneBasedIndexForDropdown,
        value: taxRate.displayName + ' at ' + taxRate.percentage.toString() + '%'
      };

      if (this.sessionData.taxRate && this.sessionData.taxRate.id === taxRate.id) {
        selectedTaxRate = taxRateDropdownItemForIndex;
      }
      return taxRateDropdownItemForIndex;
    }));

    return {
      id: selectedTaxRate.id,
      singleSelect: true,
      showSelectLabel: false,
      value: selectedTaxRate.value,
      dropdownList,
      disable: this.disableBasedOnUserRole
    };
  }

  get selectedSessionForEditPrice(): PriceResponse | undefined {
    return this.selectedSessionForEdit
      ? findSessionPrice(this.selectedSessionForEdit)
      : undefined;
  }

  get showRecurringPaymentHelper(): boolean {
    return this.sessionData.priceRequest.price > 0 &&
      this.selectedPaymentFrequencyInput === PriceType.Recurring
      && this.sessionData.priceRequest.billingCycle !== SessionBillingCycle.OneTime
      && !!this.sessionData.startDate
      && !isNaN(this.sessionData.startDate.getTime());
  }

  get recurringPaymentAmount(): number {
    const price = this.sessionData.priceRequest.price;
    const cycles = this.sessionData.priceRequest.billingCycleCount;
    let result = price;
    if (cycles > 0) {
      result = price / cycles;
    }
    return result;
  }

  get recurringPaymentEndDate(): Date | null {
    const startDate = this.sessionData.startDate;
    const billingCycle = this.sessionData.priceRequest.billingCycle;
    const billingCycleCount = this.sessionData.priceRequest.billingCycleCount;
    let result: Date | null = null;
    if (startDate && billingCycle !== SessionBillingCycle.OneTime) {
      result = new Date(startDate);
      if (billingCycle === SessionBillingCycle.Weekly) {
        result.setDate(startDate.getDate() + ((billingCycleCount - 1) * APP_CONST.DAYS_IN_A_WEEK));
      }
      else if (billingCycle === SessionBillingCycle.Monthly) {
        result.setMonth(startDate.getMonth() + billingCycleCount - 1);
      }
    }
    return result;
  }

  get recurringPaymentHelperText(): string {
    const price = formatCurrency(this.recurringPaymentAmount);
    const billingCycleOption = this.recurringPaymentIntervalDropdownOptions.find(option => option.interval === this.sessionData.priceRequest.billingCycle);
    const billingCycle = billingCycleOption
      ? billingCycleOption.displayText.toLocaleLowerCase()
      : '';
    const billingCycleCount = this.sessionData.priceRequest.billingCycleCount;
    const startDate = this.sessionData.startDate && !isNaN(this.sessionData.startDate.getTime())
      ? APP_UTILITIES.getFullDate(this.sessionData.startDate)
      : '';
    const endDate = this.recurringPaymentEndDate && !isNaN(this.recurringPaymentEndDate.getTime())
      ? APP_UTILITIES.getFullDate(this.recurringPaymentEndDate)
      : '';

    let templateText = this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_RECURRING_HELPER_TEXT');
    templateText = templateText.replace('{price}', `${price}`)
      .replace('{billingCycle}', `${billingCycle}`)
      .replace('{billingCycleCount}', `${billingCycleCount}`)
      .replace('{startDate}', `${startDate}`)
      .replace('{endDate}', `${endDate}`);

    return templateText;
  }

  get recurringPaymentHelperTooltipText(): string {
    return this.screenText.getScreenText('SESSION_ADD_EDIT_PAYMENT_SETTINGS_RECURRING_HELPER_TOOLTIP_TEXT');
  }

  async onSelectTax(selection: DropdownListItem) {
    if (selection.id == 0) {
      this.sessionData.taxRate = null;
    }
    else {
      const oneBasedDropdownIndex = selection.id;
      const convertedZeroBasedTaxRateIndex = oneBasedDropdownIndex - 1;
      this.sessionData.taxRate = this.paymentsModule.taxRates[convertedZeroBasedTaxRateIndex];
    }

    if (this.priceProviderRef && this.priceProviderRef.flags.validated) {
      await this.priceProviderRef.validate();
    }
  }

  doesDateFieldHaveEdits(field: 'ageAsOf' | 'startDate' | 'endDate'): boolean {
    if (this.selectedSessionForEdit) {
      const selectedSessionField =
        field === 'startDate'
          ? 'startDateTime'
          : field === 'endDate'
            ? 'endDateTime'
            : field;
      const initialDate = this.selectedSessionForEdit[selectedSessionField]
        ? APP_UTILITIES.getFullDate(this.selectedSessionForEdit[selectedSessionField])
        : null;
      const newDate = this.sessionData[field]
        ? APP_UTILITIES.getFullDate(this.sessionData[field])
        : null;
      return initialDate !== newDate;
    }
    return false;
  }

  doesTimeFieldHaveEdits(field: 'startTime' | 'endTime'): boolean {
    if (this.selectedSessionForEdit) {
      if (!!this.sessionData[field] !== this.selectedSessionForEdit.isTimeAdded) {
        return true;
      }
      const initialDateTimeField = field === 'startTime'
        ? 'startDateTime'
        : 'endDateTime';
      const initialTime12hrUpper = this.selectedSessionForEdit.isTimeAdded
        ? APP_UTILITIES.convert12hrFormateTimeFromDate(this.selectedSessionForEdit[initialDateTimeField]).toLocaleUpperCase()
        : '';
      const newTimeUpper = this.sessionData[field].toLocaleUpperCase();
      return newTimeUpper !== initialTime12hrUpper;
    }
    return false;
  }

  private hasTaxRateChangedForEdit(): boolean {
    if (this.hasEditData) {
      const originalTaxRateId = this.selectedSessionForEdit!.taxRate
        ? this.selectedSessionForEdit!.taxRate.id
        : null; // We can assert `selectedSessionForEdit` is not null based on `hasEditData`
      const currentTaxRateId = this.sessionData.taxRate
        ? this.sessionData.taxRate.id
        : null;
      return originalTaxRateId !== currentTaxRateId;
    }
    return false;
  }

  checkIfFormIsDirty(): boolean {
    const priceChanged = !!this.priceProviderRef && this.priceProviderRef.flags.changed;

    if (this.isEditMode && this.selectedSessionForEdit) {
      const latestPriceForSession = this.selectedSessionForEditPrice;

      const changes: { [key: string]: boolean } = {
        sessionName: this.sessionData.sessionName !== this.selectedSessionForEdit.sessionName,
        note1: this.sessionData.note1 !== this.selectedSessionForEdit.note1,
        note2: this.sessionData.note2 !== this.selectedSessionForEdit.note2,
        capactity: this.sessionData.scholarCapacity !== this.selectedSessionForEdit.scholarCapacity,
        waitListCapacity: this.sessionData.waitListCapacity !== this.selectedSessionForEdit.waitListCapacity,
        autoEnrollCapacity: this.sessionData.autoEnrollCapacity !== this.selectedSessionForEdit.autoEnrollCapacity,
        autoEnroll: this.sessionData.autoEnroll !== this.selectedSessionForEdit.autoEnroll,
        ageAsOf: this.doesDateFieldHaveEdits('ageAsOf'),
        ageTo: this.sessionData.ageTo !== this.selectedSessionForEdit.ageTo,
        ageFrom: this.sessionData.ageFrom !== this.selectedSessionForEdit.ageFrom,
        selectedDays: (
          !!this.selectedSessionForEdit
          && ((this.sessionData.selectedDays.length !== this.selectedSessionForEdit.selectedDays.length)
            || !this.selectedSessionForEdit.selectedDays.every(d => this.sessionData.selectedDays.includes(d)))
        ),
        timeAdded: this.sessionData.isTimeAdded !== this.selectedSessionForEdit.isTimeAdded,
        selectedSite: (!this.selectedSite && this.selectedSessionForEdit.siteLocationId !== undefined)
          || (!!this.selectedSite && this.selectedSite.id !== this.selectedSessionForEdit.siteLocationId),
        startDateTime: this.doesDateFieldHaveEdits('startDate'),
        endDateTime: this.doesDateFieldHaveEdits('endDate'),
        startTime: this.doesTimeFieldHaveEdits('startTime'),
        endTime: this.doesTimeFieldHaveEdits('endTime'),
        price: priceChanged,
        taxRate: this.hasTaxRateChangedForEdit(),
        waitListIsUnlimited: this.sessionData.waitListIsUnlimited !== this.selectedSessionForEdit.waitListIsUnlimited,
      };

      if (this.paymentPlanFlagEnabled) {
        changes.isPaymentPlanEnabled = this.isPaymentPlanCheckboxDirty;
      } else {
        changes.selectedRecurringPaymentInterval = !!latestPriceForSession && latestPriceForSession.billingCycle !== this.sessionData.priceRequest.billingCycle;
        changes.selectedRecurringPaymentBillingCycle = !!latestPriceForSession && latestPriceForSession.billingCycleCount !== this.sessionData.priceRequest.billingCycleCount;
      }

      this.isFormDirty = Object.values(changes).some(v => v);
    }
    else {
      this.isFormDirty = this.sessionData.ageAsOf !== null
        || this.sessionData.autoEnroll === true
        || (this.sessionData.scholarCapacity && this.sessionData.scholarCapacity > 0)
        || this.sessionData.sessionName.length > 0
        || (this.sessionData.waitListCapacity && this.sessionData.waitListCapacity > 0)
        || (this.sessionData.ageFrom && this.sessionData.ageFrom > 0)
        || (this.sessionData.ageTo && this.sessionData.ageTo > 0)
        || this.sessionData.startDate != null
        || this.sessionData.endDate != null
        || this.sessionData.startTime != ''
        || this.sessionData.endTime != ''
        || this.sessionData.selectedDays.length > 0
        || !!this.selectedSite
        || priceChanged
        || !!this.sessionData.taxRate
        || this.sessionData.priceRequest.billingCycle !== this.recurringPaymentIntervalDropdownOptions[0].interval
        || this.waitListEnabled;
    }
    return this.isFormDirty;
  }

  stripTime(date: Date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
  }

  wasTimeSet() {
    if (this.isTimeSet) {
      this.sessionData.isTimeAdded = true;
    }
    else if (this.sessionData.isTimeAdded) {
      this.sessionData.isTimeAdded = false;
    }
  }

  onBeforeClose(event: Event) {
    if (this.skipCancelConfirmation) {
      return;
    }

    if (this.sessionsModule.isSavingSessions) {
      event.preventDefault();
      return;
    }


    this.checkIfFormIsDirty();
    if (this.isFormDirty && !this.userConfirmedCancel) {
      this.isCancelConfirmationVisible = true;
      event.preventDefault();
    }
    else if (this.userConfirmedCancel) {
      this.userConfirmedCancel = false;
    }
  }

  created() {
    this.fetchAllSiteLocations();
    this.fetchProgramInformation();
    this.registerValidators();
  }

  private registerValidators() {
    requiredField(this.sessionCapacityErrorMessage);
    registerPriceRequiredValidator(this.sessionPriceRequiredErrorText);
    registerPriceMinValueValidator(this.sessionPriceInvalidErrorText);
    registerPriceGreaterThanZeroWithTaxRateValidator(this.sessionTaxPriceGreaterThanZeroErrorText);
  }

  setSessionDataToSelectedSessionForEdit() {
    if (this.isEditMode && this.selectedSessionForEdit) {
      this.sessionData.programId = this.selectedSessionForEdit.programId;
      this.sessionData.sessionName = this.selectedSessionForEdit.sessionName;
      this.sessionData.note1 = this.selectedSessionForEdit.note1;
      this.sessionData.note2 = this.selectedSessionForEdit.note2;
      this.sessionData.scholarCapacity = this.selectedSessionForEdit.scholarCapacity;
      this.sessionData.waitListCapacity = this.selectedSessionForEdit.waitListCapacity;
      this.sessionData.autoEnrollCapacity = this.selectedSessionForEdit.autoEnrollCapacity;
      this.sessionData.autoEnroll = this.selectedSessionForEdit.autoEnroll;
      this.sessionData.ageAsOf = this.selectedSessionForEdit.ageAsOf
        ? this.stripTime(new Date(this.selectedSessionForEdit.ageAsOf))
        : null;
      this.sessionData.ageTo = this.selectedSessionForEdit.ageTo;
      this.sessionData.ageFrom = this.selectedSessionForEdit.ageFrom;
      this.sessionData.selectedDays = this.selectedSessionForEdit.selectedDays;
      this.sessionData.isTimeAdded = this.selectedSessionForEdit.isTimeAdded;
      this.sessionData.startDate = this.selectedSessionForEdit.startDateTime
        ? this.stripTime(new Date(this.selectedSessionForEdit.startDateTime))
        : null;
      this.sessionData.endDate = this.selectedSessionForEdit.endDateTime
        ? this.stripTime(new Date(this.selectedSessionForEdit.endDateTime))
        : null;
      this.sessionData.startTime = this.selectedSessionForEdit.isTimeAdded
        ? APP_UTILITIES.convert12hrFormateTimeFromDate(this.selectedSessionForEdit.startDateTime).toLocaleUpperCase()
        : '';
      this.sessionData.endTime = this.selectedSessionForEdit.isTimeAdded
        ? APP_UTILITIES.convert12hrFormateTimeFromDate(this.selectedSessionForEdit.endDateTime).toLocaleUpperCase()
        : '';
      this.sessionData.siteLocationId = this.selectedSessionForEdit.siteLocationId;
      this.selectedSite = (this.siteLocationsModule.sites.find(s =>
        this.selectedSessionForEdit // Have to re-check this because of arrow function scope
        && this.selectedSessionForEdit.siteLocationId
        && s.id === this.selectedSessionForEdit.siteLocationId
      ) as VerifiedSiteLocationResponse || null); // Cast type because that model is actually what we receive back during a GET for Sites

      this.sessionData.priceRequest.price = this.getSessionDataPriceFromSelectedSessionData();
      this.sessionData.taxRate = this.selectedSessionForEdit.taxRate || null;

      const latestPriceForSession = this.selectedSessionForEditPrice;
      this.selectedPaymentFrequencyInput = latestPriceForSession && latestPriceForSession.billingCycle > 0
        ? PriceType.Recurring
        : PriceType.OneTime;

      if (this.paymentPlanFlagEnabled) {
        this.sessionData.isPaymentPlanEnabled = !!this.selectedSessionForEdit.isPaymentPlanEnabled;
        this.initialPaymentPlanCheckboxValue = this.sessionData.isPaymentPlanEnabled;
      } else {
        this.sessionData.priceRequest.billingCycle = latestPriceForSession
          ? latestPriceForSession.billingCycle
          : SessionBillingCycle.OneTime;

        this.sessionData.priceRequest.billingCycleCount = latestPriceForSession
          ? latestPriceForSession.billingCycleCount
          : MIN_BILLING_CYCLES;
      }

      this.sessionData.waitListIsUnlimited = this.selectedSessionForEdit.waitListIsUnlimited;
    }
  }

  private getSessionDataPriceFromSelectedSessionData(): number {
    const priceResponse = this.selectedSessionForEditPrice;
    if (priceResponse) {
      return priceResponse.price;
    }
    return 0;
  }

  async fetchProgramInformation() {
    const programId = APP_UTILITIES.getCookie('program_id');
    if (programId) {
      const programIdInt = Number.parseInt(programId);
      const programInfo = await this.programsModule.fetchProgramData(programIdInt);
      if (programInfo) {
        this.assignProgramDataValues(programInfo.data);
      }
    }
  }

  async fetchAllSiteLocations() {
    if (this.accountId) {
      const request: GetAllSiteLocationsRequest = {
        id: this.accountId,
        sortField: 'SiteLocationName',
        sortDir: 1
      };
      await this.siteLocationsModule.getAllSiteLocationsForAccount(request);
    }
  }

  async fetchAllTaxRates() {
    if (this.accountId) {
      await this.paymentsModule.getTaxRates(this.accountId);
    }
  }

  assignProgramDataValues(programInfo: ProgramData) {
    this.programStartDate = new Date(programInfo.programDto.startDate);
    this.programEndDate = new Date(programInfo.programDto.endDate);
    this.programStartDate.setHours(0, 0, 0, 0);
    this.programEndDate.setHours(23, 59, 59, 999);
    this.sessionData.programId = programInfo.programDto.id;
  }

  onCancel() {
    this.isAddEditSessionVisibleSync = false;
  }

  onClose() {
    this.resetData();
    this.sessionsModule.RESET_SAVE_SESSION();
    this.isSelectSitePanelOpen = false;
    this.isAddEditSessionVisibleSync = false;
    this.skipCancelConfirmation = false;
    this.isNewSessionToAddOrEdit = true;
    this.selectedPaymentFrequencyInput = this.paymentFrequencyInputOptions[0].option;
  }

  clearFormData() {
    this.sessionData = {
      programId: this.sessionData.programId,
      sessionName: '',
      note1: undefined,
      note2: undefined,
      scholarCapacity: undefined,
      waitListCapacity: undefined,
      autoEnrollCapacity: undefined,
      autoEnroll: false,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      ageAsOf: null,
      ageTo: undefined,
      ageFrom: undefined,
      selectedDays: [],
      isTimeAdded: false,
      siteLocationId: undefined,
      priceRequest: {
        price: 0,
        billingCycleCount: MIN_BILLING_CYCLES,
        ...(this.paymentPlanFlagEnabled ? {} : { billingCycle: SessionBillingCycle.OneTime })
      },
      taxRate: null,
      waitListIsUnlimited: null,
      ...(this.paymentPlanFlagEnabled ? { isPaymentPlanEnabled: this.accountPaymentPlanEnabled } : {})
    };

    this.selectedSite = null;
  }

  resetData() {
    this.hasUserSubmittedForm = false;

    this.isFormDirty = false;

    this.duplicateErrorStatus = false;

    this.hasStartDateError = false;
    this.hasEndDateError = false;

    this.hasRecurringWithNoPriceError = false;
    this.hasRecurringWithNoIntervalError = false;

    this.startTimeError = null;
    this.startTimeErrorMessage = '';

    this.endTimeError = null;
    this.endTimeErrorMessage = '';

    this.hasSessionDatesExceedProgramError = false;

    this.clearFormData();
    this.priceProviderRef && this.priceProviderRef.reset();
    this.waitListCapacityProviderRef && this.waitListCapacityProviderRef.reset();
    this.sessionCapacityProviderRef && this.sessionCapacityProviderRef.reset();
  }

  onChangeSessionStartDate(startDate: Date) {
    this.sessionData.startDate = this.stripTime(startDate);
    this.hasStartDateError = false;
  }

  onChangeSessionEndDate(endDate: Date) {
    this.sessionData.endDate = this.stripTime(endDate);
    this.hasEndDateError = false;
  }

  connectTimeToDate() {
    this.wasTimeSet();
  }

  private async canSave(isEditWorkflow: boolean): Promise<boolean> {
    this.validateStartEndTimes();

    if (this.priceProviderRef) {
      await this.priceProviderRef.validate();
      if (this.priceProviderRef.flags.invalid) {
        return false;
      }
    }

    if (this.sessionCapacityProviderRef) {
      await this.sessionCapacityProviderRef.validate();
      if (this.sessionCapacityProviderRef.flags.invalid) {
        return false;
      }
    }

    if (this.waitListCapacityProviderRef) {
      await this.waitListCapacityProviderRef.validate();
      if (this.waitListCapacityProviderRef.flags.invalid) {
        return false;
      }
    }

    if (this.hasTimeError) {
      return false;
    }

    if (this.hasAnyDateErrors) {
      return false;
    }

    if (this.hasAnyPaymentFrequencyErrors) {
      return false;
    }

    if (isEditWorkflow && this.checkIfFormIsDirty() === false) {
      return false;
    }
    return true;
  }

  private preparePayloadForSubmit(payload: AddSessionPayload | EditSessionPayload) {
    if (this.selectedSite) {
      payload.siteLocationId = this.selectedSite.id;
    }
    else if (this.selectedSessionForEdit && this.selectedSessionForEdit.siteLocationId) {
      payload.siteLocationId = undefined;
    }

    if (payload.startTime) {
      payload.startTime = APP_UTILITIES.convertTime12to24(payload.startTime);
    }

    if (payload.endTime) {
      payload.endTime = APP_UTILITIES.convertTime12to24(payload.endTime);
    }
  }

  private async updateSession(userConfirmsSoftDeleteAttendance: boolean) {
    const payload: EditSessionPayload = {
      ...this.sessionData,
      sessionId: (this.selectedSessionForEdit as SessionWithExistingSiteLocation).sessionId,
      userConfirmsSoftDeleteAttendance,
    };

    this.preparePayloadForSubmit(payload);

    this.isValidForProgressButton = false;
    await this.sessionsModule.updateSession(payload);
    this.isValidForProgressButton = true;
  }

  private async addSession() {
    const payload: AddSessionPayload = { ...this.sessionData };

    this.preparePayloadForSubmit(payload);

    this.isValidForProgressButton = false;

    await this.sessionsModule.addNewSession(payload);
    this.isValidForProgressButton = true;
    this.analyticsService.track(analyticsEventNames.SESSION_CREATED);
  }

  validateStartEndTimes(): void {

    // This is for consistency with the current form validation
    // which doesn't occur until the user has tried to submit the form
    if (!this.hasUserSubmittedForm) {
      return;
    }

    let startTimeFromDate: number | undefined;
    let endTimeFromDate: number | undefined;

    if (this.sessionData.startTime) {
      const startTimeAsDate = new Date(`1970-01-01 ${this.sessionData.startTime}`);
      startTimeFromDate = startTimeAsDate.getTime();
    }

    if (this.sessionData.endTime) {
      const endTimeAsDate = new Date(`1970-01-01 ${this.sessionData.endTime}`);
      endTimeFromDate = endTimeAsDate.getTime();
    }

    if (startTimeFromDate && endTimeFromDate && startTimeFromDate >= endTimeFromDate) {
      this.startTimeError = FormValidationError.MAX;
      this.startTimeErrorMessage = this.screenText.getScreenText('SESSION_ADD_EDIT_START_TIME_MAX_ERROR');
      this.endTimeError = FormValidationError.MIN;
      this.endTimeErrorMessage = this.screenText.getScreenText('SESSION_ADD_EDIT_END_TIME_MIN_ERROR');
    }
    else {
      if (this.startTimeError !== FormValidationError.FORMAT) {
        this.startTimeError = null;
        this.startTimeErrorMessage = '';
      }
      if (this.endTimeError !== FormValidationError.FORMAT) {
        this.endTimeError = null;
        this.endTimeErrorMessage = '';
      }
    }
  }

  @Watch('startTime')
  onStartTimeChange() {
    this.validateStartEndTimes();
  }

  @Watch('endTime')
  onEndTimeChange() {
    this.validateStartEndTimes();
  }

  onSelectRecurringPaymentInterval(intervalSelected: DropdownListItem) {
    this.sessionData.priceRequest.billingCycle = intervalSelected.id as number;
    this.sessionData.priceRequest.billingCycleCount = this.recurringPaymentBillingCycleDropdownOptions[0].billingCycle;
  }

  onSelectRecurringPaymentBillingCycle(billingCycleSelected: DropdownListItem) {
    this.sessionData.priceRequest.billingCycleCount = billingCycleSelected.id as number;
  }

  async onSubmit() {
    if (this.shouldConfirmPaymentSettingsChange()) {
      this.isPaymentSettingsChangeConfirmationVisible = true;
    }
    else if (this.shouldConfirmAttendanceDeletion()) {
      this.isRemoveAttendancePopupVisible = true;
    }
    else {
      await this.handleSubmit();
    }
  }

  private shouldConfirmPaymentSettingsChange(): boolean {
    if (this.priceProviderRef && this.priceProviderRef.flags.changed && this.isInitialStartDatePast) {
      return true;
    }
    return false;
  }

  async onConfirmPaymentSettingsChange() {
    this.isPaymentSettingsChangeConfirmationVisible = false;
    if (this.shouldConfirmAttendanceDeletion()) {
      this.isRemoveAttendancePopupVisible = true;
    }
    else {
      await this.handleSubmit();
    }
  }

  private checkIfSelectedDaysChanged(): boolean {
    if (!!this.selectedSessionForEdit
      && ((this.sessionData.selectedDays.length !== this.selectedSessionForEdit.selectedDays.length)
        || !this.selectedSessionForEdit.selectedDays.every(d => this.sessionData.selectedDays.includes(d)))) {
      return true;
    }
    return false;
  }

  private shouldConfirmAttendanceDeletion(): boolean {
    return this.checkIfSelectedDaysChanged();
  }

  async onConfirmAttendanceDeletion() {
    this.isRemoveAttendancePopupVisible = false;
    await this.handleSubmit(true);
  }

  private async handleSubmit(userConfirmsSoftDeleteAttendance = false) {
    this.hasUserSubmittedForm = true;

    const isEditWorkflow = this.isEditMode && this.hasEditData;

    if (isEditWorkflow) {
      const selectedPaymentFrequencyInput = this.selectedPaymentFrequencyInput as number;
      const paymentFrequencyChanged = selectedPaymentFrequencyInput !== this.sessionData.priceRequest.billingCycle;
      const paymentFrequencyIsOneTime = selectedPaymentFrequencyInput == PriceType.OneTime as number;
      if (paymentFrequencyChanged && paymentFrequencyIsOneTime) {
        this.sessionData.priceRequest.billingCycle = selectedPaymentFrequencyInput;
        this.sessionData.priceRequest.billingCycleCount = PriceType.OneTime as number;
      }
    }

    const canSave = await this.canSave(isEditWorkflow);

    if (canSave) {
      this.connectTimeToDate();

      if (isEditWorkflow) {
        await this.updateSession(userConfirmsSoftDeleteAttendance);
      }
      else {
        await this.addSession();
      }

      if (this.sessionsModule.didSessionsSaveFail === false) {
        this.resetData();
        this.skipCancelConfirmation = true;
        this.isAddEditSessionVisibleSync = false;
        this.$emit('onSessionUpdate');
      }
    }
  }

  onContinueConfirmation() {
    this.userConfirmedCancel = true;
    this.isCancelConfirmationVisible = false;
    this.onClose();
  }

  private createBillingCycleDropdownOptionsArray() {
    const maxBillingCycles = this.sessionData.priceRequest.billingCycle === SessionBillingCycle.Weekly
      ? MAX_BILLING_CYCLES_WEEKLY
      : MAX_BILLING_CYCLES_MONTHLY;

    const billingCycleDropdownOptions: Array<RecurringPaymentBillingCycleDropdownOptions> = new Array();
    for (let i = 2; i <= maxBillingCycles; i++) {
      billingCycleDropdownOptions.push({ billingCycle: i, displayText: i.toString() });
    }
    this.recurringPaymentBillingCycleDropdownOptions = billingCycleDropdownOptions;
  }

  private isRecurringSelectedWithNoPrice(): boolean {
    return this.selectedPaymentFrequencyInput === PriceType.Recurring
      && this.sessionData.priceRequest.price === 0;
  }

  private isRecurringSelectedWithNoInterval(): boolean {
    return this.selectedPaymentFrequencyInput === PriceType.Recurring
      && this.sessionData.priceRequest.billingCycle === 0;
  }
}

